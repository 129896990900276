import React, { useState, useEffect } from "react";
// import news from "assets/images/news.jpeg";
 //   FOR BLOGS 
import { BlogDetail } from '../../apis/home-api';
import moment from "moment";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import cogoToast from "cogo-toast";
import { Markup } from "interweave";
import MetaTags from 'react-meta-tags';
import Loader from "../common/loader/bar-loader";
export default function BlogsDetailPage(props) {
  const BlogID = props.location.search.split('=')[1];
  const [blogid, setBlogID] = useState(BlogID);
  const [blogNotFound, setBlogNotFound] = useState(false)
  const [blogConten, setBlogContent] = useState([]);
  // const [blogContenTest, setBlogContentTest] = useState([]);
  const [blogImage, setBlogImage] = useState("");
  const [blogheading, setBlogHeading] = useState("");
  const [snippetImage, setSnippetImage] = useState("");
  const [snippetDescription, setSnippetDescription] = useState("");
  const [BlogsDate, setBlogDate] = useState("");
  const [Loading, setLoading] = useState(false);
  const getBlogDetails = () => {
    setLoading(true);
    BlogDetail(blogid).then((res) => {
      if (res.status === 200) {

        if (res.data.success) {
          setLoading(false);
          // setBlogHtml(res.data.data[0].blog_content);
          setBlogDate(res.data.data[0].upload_date);
          setBlogImage(res.data.data[0].Heading_Image);
          setBlogHeading(res.data.data[0].heading);
          setBlogContent(res.data.data[0].BlogContent);
          setSnippetImage(res.data.data[0].snippetimage);
          setSnippetDescription(res.data.data[0].snippetdescription);
        } else {
          setLoading(false);
          setBlogNotFound(true);

        }
      }
    })
      .catch((err) => {

        throw err;
      })
  };
  useEffect(() => {
    getBlogDetails();
  }, []);
  // useEffect(() => {
  //   let temp = [...blogConten];
  //   blogConten.map((v, i) => {
  //     if (i % 2 === 0) {
  //       temp[i].ParaLayout = "1";
  //     }
  //     else {
  //       temp[i].ParaLayout = "3";
  //     }
  //   })
  //   setBlogContentTest(temp);
  // }, [blogConten])
  return (
    <div className="home-page-conatiner " id="BlogPage">
      {(snippetDescription) && <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content={snippetImage}
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content={snippetImage}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />

        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          property="og:description"
          content={snippetDescription}
        />
        <meta
          name="description"
          content={snippetDescription}
          data-react-helmet="true"
        />
        <meta property="og:title" content={blogheading} />
        <meta id="url" property="og:url" content={window.location.href} />
        <meta property="twitter:title" content={blogheading} />
        <meta
          property="twitter:url"
          content={window.location.href}
        />
        <meta
          property="twitter:description"
          content={snippetDescription}
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={snippetImage}></meta>
      </MetaTags>}
      {Loading ? (
        <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center main-container py-5">
       <Loader loading/>
        </div>
      ) : blogNotFound ? <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center main-container py-5"><h4>Blog not Found</h4></div> :
        (
          <div className=" event-detail-page site-page py-6">
            <div className="event-detail-page__top-section site-page__top-section">
              <header className="site-page__header w-100 mx-0 background-header blog-heading" style={blogImage !== '' ? { background: "url(" + blogImage + ")", opacity: 1 } :{ background: "url(" + blogImage + ")", opacity: 1 }}>
                <div className="  d-flex justify-content-center zindex text-center">
                  <h1 className="site-page__heading text-white fontsize">{blogheading}</h1>
                </div>
              </header>
            </div>
            <div className="container-fluid blog-content-div">
              <div className="row mt-4 w-100">
                    <div className="col-2">
                      <div className="mt-3">
                        <div className="blogShareIcons BlogShare d-flex justify-content-center">
                          {/* <span>Link to be updated</span> */}
                          <FacebookShareButton
                            className="margin-top mb-4 pad-2"
                            url={window.location.href}
                          >
                            <i
                              className="fa fa-facebook-square"
                              aria-hidden="true"
                              title="Copy To Clipboard"
                            ></i>
                          </FacebookShareButton>

                          <TwitterShareButton
                            className="mb-4 pad-2"
                            url={window.location.href}
                          >
                            <i
                              className="fa fa-twitter-square"
                              aria-hidden="true"
                            ></i>
                          </TwitterShareButton>

                          <LinkedinShareButton
                            className="mb-4 pad-2"
                            url={window.location.href}
                          >
                            <i
                              className="fa fa-linkedin-square"
                              aria-hidden="true"
                            ></i>
                          </LinkedinShareButton>

                          <img
                            className="fa fa-link CopyBtn "
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC5UlEQVR4nO2dP67TQBCHlz8CJMQFEDXiBNCAgAqQEBLZsaBAoqENFOhlJ5XvgDgJBTdA71G9cIYnGlrwrqkWbSiQkJzY3tiza/8+aUor3i+zk/FE2SgFAAAAADBZlo/9ZVPU7wy5E9b2F5PzQ4fR9jeT+7IqqttqDqyeVzcM2W9jyG0Q7lYL90hNPpNJTvJsZLOu30pLnoVs1u6rtOBZyDba/hQRSraalWwWyty1tvd2vcnbjkTXT9VUYCHR4bVnJZsFRc9KNguLno1sTkD0LGRzIqInL5sTEj1p2ZyY6MnK5gRFB0xh7yYrOwyItrOL8Fj936gzN9GB9cI9TO4Jct+oM0fRyckuX/sr+0aduYpuK5t1dUcNTfhmpO/CWEj00cvqeqc1avdgl+zwTY1KYdSZmmjW1nRd5y7Z4cNRKX8uWmbsqDM10WbbNVhzyMweXHSbhcVcy5kERBNEK+ksREaTvDiUDpKXihpNEI2uI5Z226r/tZxJoL0jiFbSWYiMJnlxKB0kLxU1miAaXUcs7bZV/2s5k0B7RxCtpLMQGU3y4lA6SF4qajRBNLqOWNptq/7XciaB9o4gWklnITKa5MWhdJC8VNRogmh0HbG021b9r+VMAu0dQbSSzkJkNMmLQ+kgeanJ1uijZ/5aSgej8IEjrGNQyW1F84v6Zg5H/XBf0eROkhC90vWT1A+v4jjRyyREM9mPjb+2JbuRFhUXdhPWkYhod1YU/kLTr245W9l2E+5/cMkdRIft9Wb3QYNuyeSOxzoys3f8vb/jcL+jZHJX0azd96buA7Sg41b73FRCwB56bL0PZenPQ2xHen6IfEIZGUW080a7H+FkhPK+v4jsHlA0/4uz0GeHh5r1or71/pW/CvHDiPazGQzFIC2HIVpeHE8pozHqHAmMOkeizeFVOYQZY9QZwxRGnUa707Lwl1Tq5DzqNNqdjjbqPOSRmWP+OxAfYNSZRSYDAAAAAAAAgBqRP6QjTRN/lhlbAAAAAElFTkSuQmCC"
                            onClick={() => {
                              const el = document.createElement("input");
                              el.value = window.location.href;
                              document.body.appendChild(el);
                              el.select();
                              document.execCommand("copy");
                              document.body.removeChild(el);
                              cogoToast.success("Link copied to clipboard");
                            }}
                          ></img>
                        </div>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="d-flex justify-content-start">
                        <h5 className="mt-2 text-muted "> Published Date:<i>&nbsp; {moment(BlogsDate).format("DD MMMM yyyy")}</i> </h5>
                      </div>
                      <div className="mt-3 mb-5  text-justify blog-content">
                        {blogConten !== [] ? blogConten.map((data, i) => (
                          <>
                            {data.paragraph_layout == '0' ?
                              <div className="blogPara my-5" key={i}>
                                <Markup content={data.description} className='htmlspan' />
                                {data.image !== '' ? <div className=" my-5 text-center">
                                  <img className='rounded mx-auto d-block img-fluid paraImage border' src={data.image} />
                                </div> : ""}
                              </div>
                              : data.paragraph_layout == '1' ?
                                <div className="blogPara my-5" key={i}>
                                  {data.image !== '' ? <div className="my-5 text-center">
                                    <img className='rounded mx-auto d-block img-fluid paraImage border' src={data.image} />
                                  </div> : ""}
                                  <Markup content={data.description} className='htmlspan' />
                                </div> :
                                data.paragraph_layout == '2' ?
                                  <div className="row blogPara my-5 " key={i}>
                                    <div className="col-lg-5 col-md-12 order-2 order-lg-1 mt-tab-5">
                                      {data.image !== '' ? <div className="text-center">
                                        <img className='rounded img-fluid border para-layout-img' src={data.image} />
                                      </div> : ""}
                                    </div>
                                    <div className="col-lg-7 col-md-12  order-1 order-lg-2">
                                      <Markup content={data.description} className='htmlspan' />
                                    </div>
                                  </div> : data.paragraph_layout == '3' ?
                                    <div className="row blogPara my-5" key={i}>
                                      <div className="col-lg-7 col-md-12 order-1 order-lg-1">
                                        <Markup content={data.description} className='htmlspan' />
                                      </div>
                                      <div className="col-lg-5 col-md-12 order-2 order-lg-2 mt-tab-5">
                                        {data.image !== '' ? <div className="text-center">
                                          <img className='rounded img-fluid border para-layout-img' src={data.image} />
                                        </div> : ""}
                                      </div>
                                    </div> : ""}
                          </>
                        )) : ""}

                      </div>
                    </div>
                  </div>
              
                {/* <div className="col-lg-3 col-md-3 col-sm-12">
            <h4>Similar blogs</h4>
            </div> */}
              </div>
            </div>)}
    </div>
  );
}
