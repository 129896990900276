
import { useState, useEffect } from "react";

import metaId from "./meta.json";

global.appVersion = metaId.id;


function CacheBuster() {
   const [loading, setLoading] = useState(true);
   const [isLatest, setIsLatest] = useState(false);

   function refreshCacheAndReload() {
      console.error("Clearing cache and hard reloading...");
      if (caches) {
         // Service worker cache should be cleared with caches.delete()
         caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
         });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
   }

   useEffect(() => {
      fetch("/meta.json", { cache: "no-store" })
         .then((response) => response.json())
         .then((meta) => {
            const latestVersion = meta.version;
            const currentVersion = global.appVersion;

            const shouldForceRefresh = latestVersion !== currentVersion;
            if (shouldForceRefresh) {
               setLoading(false);
            } else {
               setIsLatest(true);
               setLoading(false);
            }
         });
   }, []);
   
   return { loading, isLatest, refreshCacheAndReload };
}

export default CacheBuster;