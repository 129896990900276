import React, { useState, useEffect } from 'react'
import Loader from '../common/loader/bar-loader';
//   FOR BLOGS 
import { BlogList } from '../../apis/home-api';
// import ShowMoreText from "react-show-more-text";
import { Markup } from 'interweave';
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
const ResourceDetailListPage = () => {
  const [page, setpage] = useState(1);
  const [totalpage, setTotalPAge] = useState(1);
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getBlogList(page, 3);
  }, [])

  //   FOR BLOGS
  const getBlogList = (pageno, pagesize) => {
    setLoading(true)
    BlogList(pageno, pagesize).then((res) => {
      setLoading(false);
      setBlogList(res.data.data);
      setTotalPAge(res.data.page_count < pagesize ? 1 : Math.ceil(res.data.page_count / pagesize));
    })
  }
  const handlePageChange = (event, value) => {
    setBlogList([]);
    setpage(value);
    getBlogList(value, 3)
  };

  return (<>
    <div className="" id='ResourcePag'>

      <div className=' main-container1  container ' >

        <div className="row">
          <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 pt-50">
            <h4 className='resourceSubHeading '>Blogs</h4>
            <hr className='resourcesCathr'/>
          </div>
        </div>
        {loading ? <div className='row  pt-110 justify-content-center'><div className='col-3 text-center'><Loader loading /></div></div> : <>
          <div className=''>
            <div className="row pt-30 w-100 ">
              {blogList.length !== 0 ? blogList.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="col-xl-4 col-lg-4 col-md-6  col-wd-2 text-center mb-4"
                  >
                    <a className="home-in" href={`/blog?name=${item.heading.split(" ").join("-")}`}>
                      <div className="card lg-card in-cursor blog-list-card h-100">
                        <div className='p08rem'>
                          <img
                            loading="lazy"
                            className="card-img-top blog-img-card-top"
                            src={item.Heading_Image !== '' ? item.Heading_Image : item.Heading_Image}
                            alt="Card image"
                          />
                        </div>
                        <div className="card-body">
                        <p className="card-pera font-size-13 purple">
                            {format(new Date(item.upload_date), "d MMMM yyyy")}
                          </p>
                          <p className="card-text font-size-16">
                            {item.heading}
                          </p>
                          <div className="card-pera1 font-size-12 mt-4">
                              {<Markup content={item.description} />}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              }) : <div className='w-100 text-center'><h4>No Blogs Found</h4></div>}
            </div>
            <div className="row mb-4 mt-2 justify-content-center">
              {
                (!loading && blogList.length !== 0) ? <Pagination
                  count={totalpage}
                  page={page}
                  showFirstButton={true}
                  showLastButton={true}
                  onChange={handlePageChange}
                /> : ""
              }

            </div>
          </div>
        </>}
      </div>



    </div>
  </>
  )
}

export default ResourceDetailListPage