export const authresponse =
{
"access_token": "*******************************",
"expire": "30 minute's"
}
export const screeningAPIReqMsg = {
  "projectId": 3,
  "startDate": "",
  "endDate": "",
  "entityName": "Aung Pang Sone",
  "industry": "",
  "panNumber": null,
  "PassportNumber": [],
  "CountryOfResidence": [
    "India"
  ],
  "emails": [
    "abc@gmail.com"
  ],
  "phoneNumbers": [
    "+911234567890"
  ],
  "reportType": "Detailed",
  "dateofincorporation" : "",
  "organization": [],
  "identifiers": [],
  "designation": null,
  "type": "Individual",
  "vesselsType": null,
  "sector": null,
  "country": [
    "Myanmar"
  ],
  "gender": null,
  "dob": "02-11-2000",
  "cin": null,
  "din": null,
  "state": null,
  "city": null,
  "aliases": [],
  "associations": [],
  "nationality": "string",
  "projectSetting": {
    "entityInputCheckModels": [
      {
        "checkName": "Indian Watchlists",
        "thresholdName": "Low",
        "thresholdvalue": 0,
        "thresholdStartRange": 0,
        "thresholdEndRange": 50,
        "checksWeightage": 0.5,
        "listName": [],
        "listUId": [],
        "maxScore": 2
      },
      {
        "checkName": "Indian Watchlists",
        "thresholdName": "Medium",
        "thresholdvalue": 1,
        "thresholdStartRange": 51,
        "thresholdEndRange": 90,
        "checksWeightage": 0.5,
        "listName": [],
        "listUId": [],
        "maxScore": 2
      },
      {
        "checkName": "Indian Watchlists",
        "thresholdName": "High",
        "thresholdvalue": 2,
        "thresholdStartRange": 91,
        "thresholdEndRange": 100,
        "checksWeightage": 0.5,
        "listName": [],
        "listUId": [],
        "maxScore": 2
      },
      {
        "checkName": "Indonesia Watchlists",
        "thresholdName": "Low",
        "thresholdvalue": 0,
        "thresholdStartRange": 0,
        "thresholdEndRange": 50,
        "checksWeightage": 0.5,
        "listName": [],
        "listUId": [],
        "maxScore": 2
      },
      {
        "checkName": "Indonesia Watchlists",
        "thresholdName": "Medium",
        "thresholdvalue": 1,
        "thresholdStartRange": 51,
        "thresholdEndRange": 90,
        "checksWeightage": 0.5,
        "listName": [],
        "listUId": [],
        "maxScore": 2
      },
      {
        "checkName": "Indonesia Watchlists",
        "thresholdName": "High",
        "thresholdvalue": 2,
        "thresholdStartRange": 91,
        "thresholdEndRange": 100,
        "checksWeightage": 0.5,
        "listName": [],
        "listUId": [],
        "maxScore": 2
      },
      {
        "checkName": "UANI Check",
        "thresholdName": "Low",
        "thresholdvalue": 0,
        "thresholdStartRange": 0,
        "thresholdEndRange": 50,
        "checksWeightage": 0.5,
        "maxScore": 2
      },
      {
        "checkName": "UANI Check",
        "thresholdName": "Medium",
        "thresholdvalue": 1,
        "thresholdStartRange": 51,
        "thresholdEndRange": 90,
        "checksWeightage": 0.5,
        "maxScore": 2
      },
      {
        "checkName": "UANI Check",
        "thresholdName": "High",
        "thresholdvalue": 2,
        "thresholdStartRange": 91,
        "thresholdEndRange": 100,
        "checksWeightage": 0.5,
        "maxScore": 2
      }
    ],
    "compositeScoreAlgoName": "max",
    "entityScoreAlgoName": "max",
    "checkResultCount": {
      "Indian Watchlists": 3,
      "Indonesia Watchlists": 3,
      "UANI Check": 3
    },
    "caseScoreMapping": [
      {
        "status": "Green",
        "startRange": 0,
        "endRange": 1
      },
      {
        "status": "Amber",
        "startRange": 2,
        "endRange": 7
      },
      {
        "status": "Red",
        "startRange": 8,
        "endRange": 10
      }
    ],
    "entityScoreMapping": [
      {
        "status": "Green",
        "startRange": 0,
        "endRange": 1
      },
      {
        "status": "Amber",
        "startRange": 2,
        "endRange": 7
      },
      {
        "status": "Red",
        "startRange": 8,
        "endRange": 10
      }
    ],
    "compositeScoreConfiguration": {
      "methodType": "Max Weighted",
      "parameterWeightage": [
        {
          "paramType": "Primary",
          "paramWeight": 100,
          "paramName": "Name"
        },
        {
          "paramType": "Secondary",
          "paramWeight": 0,
          "paramName": "PAN"
        },
        {
          "paramType": "Secondary",
          "paramWeight": 0,
          "paramName": "Passport Number"
        },
        {
          "paramType": "Secondary",
          "paramWeight": 0,
          "paramName": "CIN"
        },
        {
          "paramType": "Secondary",
          "paramWeight": 0,
          "paramName": "DIN"
        },
        {
          "paramType": "Tertiary",
          "paramWeight": 0,
          "paramName": "Phone"
        },
        {
          "paramType": "Tertiary",
          "paramWeight": 0,
          "paramName": "Email"
        },
        {
          "paramType": "Tertiary",
          "paramWeight": 0,
          "paramName": "DOB"
        },
        {
          "paramType": "Tertiary",
          "paramWeight": 0,
          "paramName": "Gender"
        }
      ]
    },
    "nameMatchingThresholds": [
      {
        "checkName": "Indian Watchlists",
        "thresholdName": "Low",
        "thresholdValue": 0,
        "startRange": 0,
        "endRange": 89
      },
      {
        "checkName": "Indian Watchlists",
        "thresholdName": "Medium",
        "thresholdValue": 1,
        "startRange": 90,
        "endRange": 95
      },
      {
        "checkName": "Indian Watchlists",
        "thresholdName": "High",
        "thresholdValue": 2,
        "startRange": 96,
        "endRange": 100
      },
      {
        "checkName": "UANI Check",
        "thresholdName": "Low",
        "thresholdValue": 0,
        "startRange": 0,
        "endRange": 89
      },
      {
        "checkName": "UANI Check",
        "thresholdName": "Medium",
        "thresholdValue": 1,
        "startRange": 90,
        "endRange": 95
      },
      {
        "checkName": "UANI Check",
        "thresholdName": "High",
        "thresholdValue": 2,
        "startRange": 96,
        "endRange": 100
      },
      {
        "checkName": "Indonesia Watchlists",
        "thresholdName": "Low",
        "thresholdValue": 0,
        "startRange": 0,
        "endRange": 89
      },
      {
        "checkName": "Indonesia Watchlists",
        "thresholdName": "Medium",
        "thresholdValue": 1,
        "startRange": 90,
        "endRange": 95
      },
      {
        "checkName": "Indonesia Watchlists",
        "thresholdName": "High",
        "thresholdValue": 2,
        "startRange": 96,
        "endRange": 100
      }
    ]
  }
}

export const screeningAPIResMsg = {
  "pdfUrl": "CL1684075_Detailed.pdf",
  "entitychecks": [
    {
      "UANI Check": [
        {
          "Rule Applied": "",
          "Priority": "",
          "ListUid": "",
          "Date Sanctioned by Canada": "",
          "Match Score": "",
          "Full Name": "",
          "Alias": "",
          "Address": "",
          "Citizenship": "",
          "Email Match Status": "",
          "Email": "",
          "Email Match Details": "",
          "Phone Number Match Status": "",
          "Phone Number": "",
          "Phone Number Match Details": "",
          "List Name": "",
          "Sources": "",
          "Website": "",
          "Name of Associated Subject": "",
          "Sanctioned by United States": "",
          "Date Sanctioned by United States": "",
          "United States Laws and Executive Orders Sanctioned Under": "",
          "Sanctioned by European Union": "",
          "Date Sanctioned by European Union": "",
          "European Union Laws and Executive Orders Sanctioned Under": "",
          "Sanctioned by United Kingdom": "",
          "Date Sanctioned by United Kingdom": "",
          "United Kingdom Laws and Executive Orders Sanctioned Under": "",
          "Sanctioned by Canada": "",
          "details": "No Hits Found",
          "Canada Laws and Executive Orders Sanctioned Under": "",
          "Sanctioned by Australia": "",
          "Date Sanctioned by Australia": "",
          "Australia Laws and Executive Orders Sanctioned Under": "",
          "Penalty Amount": "",
          "Imprisonment": "",
          "Authority": "",
          "composite_score": "",
          "InputType": "Individual",
          "InputEntityName": "Aung Pang Sone",
          "InputAlias": [],
          "InputIdentifier_4": [],
          "InputDesignation": "",
          "InputAssociations": [],
          "InputAddress": "",
          "InputState": [],
          "InputCountry": [
            "Myanmar"
          ],
          "InputCity": "",
          "InputCin": "",
          "InputDin": "",
          "InputEmailID": [
            "abc@gmail.com"
          ],
          "InputPhoneNumber": [
            "+911234567890"
          ],
          "InputSector": "",
          "InputIndustry": "",
          "InputOrganization": [],
          "InputDateofBirth": "02 Nov 2000",
          "InputPan": "",
          "InputGender": "",
          "InputNationality": "string",
          "InputVesselsType": "",
          "InputCountryOfResidence": [
            "India"
          ],
          "InputPassport": [],
          "InputIdIdentifiers": [],
          "InputDateOfIncorporation": "",
          "timestamp": "10 May 2024, GMT 02:07:26",
          "match_status": "Green",
          "match_score": "0",
          "Comment": "",
          "Match": "",
          "FinalStatus": "",
          "FinalScore": "",
          "FinalTimeStamp": "",
          "Reviewer": "",
          "ReviewerId": 0,
          "Label": "",
          "RunId": 217134,
          "mappingId": 1,
          "Attachment": "",
          "fileName": ""
        }
      ],
      "entityType": "Individual",
      "entityName": "Aung Pang Sone",
      "totalResponseCount": 3,
      "HitsFound": {
        "UANI Check": 0,
        "Indonesia Watchlists": 0,
        "Indian Watchlists": 3
      },
      "Indonesia Watchlists": [
        {
          "Rule Applied": "",
          "details": "No Hits Found",
          "Match Score": "",
          "Full Name": "",
          "Authority": "",
          "List Name": "",
          "Alias": "",
          "Address": "",
          "Regency": "",
          "Reason": "",
          "Position": "",
          "Court Name": "",
          "Case Type": "",
          "Case File Number": "",
          "Case File Date": "",
          "Corruption Crime Case Number": "",
          "Department Number": "",
          "Department Date": "",
          "Official Memorandum Number": "",
          "Service Memorandum Number": "",
          "Service Memorandum Date": "",
          "Sprin": "",
          "Sprin Date": "",
          "Sprin Juk Number": "",
          "Sprin Juk Date": "",
          "Nomor Pendaftaran Wajib Pajak (NPWP)": "",
          "Determination Decree": "",
          "Violation": "",
          "KLPD": "",
          "Satuan Kerja Satker Name": "",
          "Sanction Validity Period From": "",
          "Sanction Validity Period To": "",
          "Airing Date": "",
          "Section": "",
          "Decision Number": "",
          "Date of Decision": "",
          "Outcome": "",
          "Sources": "",
          "Priority": "",
          "ListUid": "",
          "composite_score": "",
          "InputType": "Individual",
          "InputEntityName": "Aung Pang Sone",
          "InputAlias": [],
          "InputIdentifier_4": [],
          "InputDesignation": "",
          "InputAssociations": [],
          "InputAddress": "",
          "InputState": [],
          "InputCountry": [
            "Myanmar"
          ],
          "InputCity": "",
          "InputCin": "",
          "InputDin": "",
          "InputEmailID": [
            "abc@gmail.com"
          ],
          "InputPhoneNumber": [
            "+911234567890"
          ],
          "InputSector": "",
          "InputIndustry": "",
          "InputOrganization": [],
          "InputDateofBirth": "02 Nov 2000",
          "InputPan": "",
          "InputGender": "",
          "InputNationality": "string",
          "InputVesselsType": "",
          "InputCountryOfResidence": [
            "India"
          ],
          "InputPassport": [],
          "InputIdIdentifiers": [],
          "InputDateOfIncorporation": "",
          "timestamp": "10 May 2024, GMT 02:07:26",
          "match_status": "Green",
          "match_score": "0",
          "Comment": "",
          "Match": "",
          "FinalStatus": "",
          "FinalScore": "",
          "FinalTimeStamp": "",
          "Reviewer": "",
          "ReviewerId": 0,
          "Label": "",
          "RunId": 217134,
          "mappingId": 1,
          "Attachment": "",
          "fileName": ""
        }
      ],
      "Indian Watchlists": [
        {
          "Passport Match Status": "",
          "GenderMatchStatus": "",
          "GenderMatchDetails": "",
          "List Type": "",
          "UN List Type": "",
          "Sanctions Authority ID": "",
          "Sanction Date": "",
          "Date of Birth (DOB) from Year": "",
          "Date of Birth (DOB) to Year": "",
          "Period": "",
          "Order Date": "14 Mar 2013",
          "Date of Arbitration Award": "",
          "Date of Appellate Award": "",
          "Identification Details Name": "",
          "Identification Details Value": "",
          "Identification Details Country": "",
          "Identification Details Issue Date": "",
          "Identification Details Note": "",
          "Additional Information": "",
          "ListUid": "IWL_A00019L000002",
          "Order Effective from Date": "",
          "Order Effective to Date": "",
          "Application Number": "",
          "Date of Exchange Circular": "",
          "CP2 Number": "",
          "Name of Office": "",
          "Number of Employees": "",
          "Inspection Division": "",
          "Identification Details City": "",
          "Name in Original Script": "",
          "Address Note": "",
          "Date of Birth Note": "",
          "Employees State Insurance Corporation (ESIC) Code Number": "",
          "Recovery Certificate Number": "",
          "Issued Date": "",
          "Certificate Amount": "",
          "Interest Amount": "",
          "Further Interest Amount": "",
          "Amount Paid": "",
          "Total Amount": "",
          "Balance Amount": "",
          "Default Amount": "",
          "Due Amount": "",
          "Certificate Type": "",
          "Period of Claim": "",
          "Claim Type": "",
          "Case Status": "",
          "First Information Report FIR Number": "",
          "State Match Status": "",
          "State Match Details": "",
          "State": "",
          "Rule Applied": "",
          "Priority": "",
          "Passport": "",
          "Position": "",
          "DIN": "",
          "Reason": "",
          "Country": "Myanmar",
          "Authority Name": "Wildlife Crime Control Bureau",
          "ReasonofSuspension": "",
          "DOB": "",
          "DefaulterExpelledSuspensiondate": "",
          "Charges": "",
          "Age": 28,
          "DobScore": "0%",
          "Current Status": "",
          "PenaltyAmount": "10,000",
          "fuzzy_score": "79%",
          "Reward": "",
          "ListName": "Wildlife Crime Control Bureau - Convicts",
          "Status": "",
          "Address": "Myanmar",
          "SourceLink": "http://wccb.gov.in/Content/Convicts.aspx",
          "details": "aung aung thin htun",
          "SourceName": "India Wildlife Crime Control Bureau",
          "MatchedPanNo": "",
          "Nationality": "",
          "Image Url": "",
          "Name": "Aung Aung Thin Htun",
          "DistinguishingMarks": "",
          "Alias": "",
          "DIN_match_details_ds": "",
          "NameofAssociatedCompany": "",
          "DobMatchDetails": "",
          "Gender": "",
          "PAN match status": "",
          "Email Match Status": "Not Available",
          "Email": "",
          "Email Match Details": "",
          "Phone Number": "",
          "Phone Number Match Status": "Not Available",
          "Phone Number Match Details": "",
          "DIN_match_status_ds": "",
          "Branch": "",
          "Outstanding Amount": "",
          "Lender Bank": "",
          "Court Name": "",
          "Quarter": "",
          "CourtJudgement": "",
          "CaseName": "",
          "CaseNumber": "183/2013 ",
          "Section": "Section 50/51 Wild Life (P) Act",
          "Appeal Number": "",
          "Date of Appeal": "",
          "Notice Date": "",
          "Suit": "",
          "SEBI Reference Number": "",
          "Vendor Identification Number": "",
          "Debarred Date From": "",
          "Debarred Date To": "",
          "SEBI Registration Number": "",
          "Complaint Number": "",
          "Registration Number": "",
          "Violation": "",
          "Forward Markets Commission (FMC) Registration Number": "",
          "Institute of Chartered Accountants of India (ICAI) Membership Number": "",
          "Outcome": "",
          "Purpose": "",
          "Name of Respondent": "",
          "Defendant Name": "",
          "Account Name": "",
          "Nature of Offence": "",
          "Imprisonment Term": "",
          "Government Notification Number": "",
          "Date of Offence": "",
          "Absconding Status": "",
          "Absconding Date": "",
          "Action Taken": "",
          "Action": "",
          "Case Summary": "",
          "Police Station (PS)": "",
          "First Information Report FIR Date": "",
          "Date of Filing": "",
          "Next Listing Date": "",
          "Name of All Petitioners": "",
          "Name of All Respondents": "",
          "Date of Press Release": "",
          "Investigation Date": "",
          "Investigation Status": "",
          "Seized Amount": "",
          "Date of Arrested": "",
          "Case Filing Date": "",
          "Scrip Name": "",
          "Filing Number": "",
          "Passport Match Details": "",
          "composite_score": "79%",
          "Case Number": "183/2013 ",
          "CIN_match_status_ds": "",
          "CIN": "",
          "CIN_match_details_ds": "",
          "Identifier Match Status": "",
          "Identifier Match Score": "",
          "Identifier Match Details": [],
          "Identifier": [],
          "Other Details": [],
          "Goods and Services Tax (GST) Network Number": "",
          "timestamp": "10 May 2024, GMT 02:07:26",
          "match_score": 1,
          "match_status": "Green",
          "Comment": "",
          "Match": "",
          "FinalStatus": "",
          "FinalScore": "",
          "FinalTimeStamp": "",
          "Reviewer": "",
          "ReviewerId": 0,
          "Label": "",
          "RunId": 217134,
          "Attachment": "",
          "fileName": "",
          "mappingId": 1,
          "InputType": "Individual",
          "InputEntityName": "Aung Pang Sone",
          "InputAlias": [],
          "InputIdentifier_4": [],
          "InputDesignation": "",
          "InputAssociations": [],
          "InputAddress": "",
          "InputState": [],
          "InputCountry": [
            "Myanmar"
          ],
          "InputCity": "",
          "InputCin": "",
          "InputDin": "",
          "InputEmailID": [
            "abc@gmail.com"
          ],
          "InputPhoneNumber": [
            "+911234567890"
          ],
          "InputSector": "",
          "InputIndustry": "",
          "InputOrganization": [],
          "InputDateofBirth": "02 Nov 2000",
          "InputPan": "",
          "InputGender": "",
          "InputNationality": "string",
          "InputVesselsType": "",
          "InputCountryOfResidence": [
            "India"
          ],
          "InputPassport": [],
          "InputIdIdentifiers": [],
          "InputDateOfIncorporation": ""
        },
        {
          "Passport Match Status": "",
          "GenderMatchStatus": "",
          "GenderMatchDetails": "",
          "List Type": "",
          "UN List Type": "",
          "Sanctions Authority ID": "",
          "Sanction Date": "",
          "Date of Birth (DOB) from Year": "",
          "Date of Birth (DOB) to Year": "",
          "Period": "",
          "Order Date": "14 Mar 2013",
          "Date of Arbitration Award": "",
          "Date of Appellate Award": "",
          "Identification Details Name": "",
          "Identification Details Value": "",
          "Identification Details Country": "",
          "Identification Details Issue Date": "",
          "Identification Details Note": "",
          "Additional Information": "",
          "ListUid": "IWL_A00019L000002",
          "Order Effective from Date": "",
          "Order Effective to Date": "",
          "Application Number": "",
          "Date of Exchange Circular": "",
          "CP2 Number": "",
          "Name of Office": "",
          "Number of Employees": "",
          "Inspection Division": "",
          "Identification Details City": "",
          "Name in Original Script": "",
          "Address Note": "",
          "Date of Birth Note": "",
          "Employees State Insurance Corporation (ESIC) Code Number": "",
          "Recovery Certificate Number": "",
          "Issued Date": "",
          "Certificate Amount": "",
          "Interest Amount": "",
          "Further Interest Amount": "",
          "Amount Paid": "",
          "Total Amount": "",
          "Balance Amount": "",
          "Default Amount": "",
          "Due Amount": "",
          "Certificate Type": "",
          "Period of Claim": "",
          "Claim Type": "",
          "Case Status": "",
          "First Information Report FIR Number": "",
          "State Match Status": "",
          "State Match Details": "",
          "State": "",
          "Rule Applied": "",
          "Priority": "",
          "Passport": "",
          "Position": "",
          "DIN": "",
          "Reason": "",
          "Country": "Myanmar",
          "Authority Name": "Wildlife Crime Control Bureau",
          "ReasonofSuspension": "",
          "DOB": "",
          "DefaulterExpelledSuspensiondate": "",
          "Charges": "",
          "Age": 41,
          "DobScore": "0%",
          "Current Status": "",
          "PenaltyAmount": "10,000",
          "fuzzy_score": "72%",
          "Reward": "",
          "ListName": "Wildlife Crime Control Bureau - Convicts",
          "Status": "",
          "Address": "Myanmar",
          "SourceLink": "http://wccb.gov.in/Content/Convicts.aspx",
          "details": "win aung",
          "SourceName": "India Wildlife Crime Control Bureau",
          "MatchedPanNo": "",
          "Nationality": "",
          "Image Url": "",
          "Name": "Win Aung",
          "DistinguishingMarks": "",
          "Alias": "",
          "DIN_match_details_ds": "",
          "NameofAssociatedCompany": "",
          "DobMatchDetails": "",
          "Gender": "",
          "PAN match status": "",
          "Email Match Status": "Not Available",
          "Email": "",
          "Email Match Details": "",
          "Phone Number": "",
          "Phone Number Match Status": "Not Available",
          "Phone Number Match Details": "",
          "DIN_match_status_ds": "",
          "Branch": "",
          "Outstanding Amount": "",
          "Lender Bank": "",
          "Court Name": "",
          "Quarter": "",
          "CourtJudgement": "",
          "CaseName": "",
          "CaseNumber": "183/2013 ",
          "Section": "Section 50/51 Wild Life (P) Act",
          "Appeal Number": "",
          "Date of Appeal": "",
          "Notice Date": "",
          "Suit": "",
          "SEBI Reference Number": "",
          "Vendor Identification Number": "",
          "Debarred Date From": "",
          "Debarred Date To": "",
          "SEBI Registration Number": "",
          "Complaint Number": "",
          "Registration Number": "",
          "Violation": "",
          "Forward Markets Commission (FMC) Registration Number": "",
          "Institute of Chartered Accountants of India (ICAI) Membership Number": "",
          "Outcome": "",
          "Purpose": "",
          "Name of Respondent": "",
          "Defendant Name": "",
          "Account Name": "",
          "Nature of Offence": "",
          "Imprisonment Term": "",
          "Government Notification Number": "",
          "Date of Offence": "",
          "Absconding Status": "",
          "Absconding Date": "",
          "Action Taken": "",
          "Action": "",
          "Case Summary": "",
          "Police Station (PS)": "",
          "First Information Report FIR Date": "",
          "Date of Filing": "",
          "Next Listing Date": "",
          "Name of All Petitioners": "",
          "Name of All Respondents": "",
          "Date of Press Release": "",
          "Investigation Date": "",
          "Investigation Status": "",
          "Seized Amount": "",
          "Date of Arrested": "",
          "Case Filing Date": "",
          "Scrip Name": "",
          "Filing Number": "",
          "Passport Match Details": "",
          "composite_score": "72%",
          "Case Number": "183/2013 ",
          "CIN_match_status_ds": "",
          "CIN": "",
          "CIN_match_details_ds": "",
          "Identifier Match Status": "",
          "Identifier Match Score": "",
          "Identifier Match Details": [],
          "Identifier": [],
          "Other Details": [],
          "Goods and Services Tax (GST) Network Number": "",
          "timestamp": "10 May 2024, GMT 02:07:26",
          "match_score": 1,
          "match_status": "Green",
          "Comment": "",
          "Match": "",
          "FinalStatus": "",
          "FinalScore": "",
          "FinalTimeStamp": "",
          "Reviewer": "",
          "ReviewerId": 0,
          "Label": "",
          "RunId": 217134,
          "Attachment": "",
          "fileName": "",
          "mappingId": 2,
          "InputType": "Individual",
          "InputEntityName": "Aung Pang Sone",
          "InputAlias": [],
          "InputIdentifier_4": [],
          "InputDesignation": "",
          "InputAssociations": [],
          "InputAddress": "",
          "InputState": [],
          "InputCountry": [
            "Myanmar"
          ],
          "InputCity": "",
          "InputCin": "",
          "InputDin": "",
          "InputEmailID": [
            "abc@gmail.com"
          ],
          "InputPhoneNumber": [
            "+911234567890"
          ],
          "InputSector": "",
          "InputIndustry": "",
          "InputOrganization": [],
          "InputDateofBirth": "02 Nov 2000",
          "InputPan": "",
          "InputGender": "",
          "InputNationality": "string",
          "InputVesselsType": "",
          "InputCountryOfResidence": [
            "India"
          ],
          "InputPassport": [],
          "InputIdIdentifiers": [],
          "InputDateOfIncorporation": ""
        },
        {
          "Passport Match Status": "",
          "GenderMatchStatus": "",
          "GenderMatchDetails": "",
          "List Type": "",
          "UN List Type": "",
          "Sanctions Authority ID": "",
          "Sanction Date": "",
          "Date of Birth (DOB) from Year": "",
          "Date of Birth (DOB) to Year": "",
          "Period": "",
          "Order Date": "02 Feb 2013",
          "Date of Arbitration Award": "",
          "Date of Appellate Award": "",
          "Identification Details Name": "",
          "Identification Details Value": "",
          "Identification Details Country": "",
          "Identification Details Issue Date": "",
          "Identification Details Note": "",
          "Additional Information": "",
          "ListUid": "IWL_A00019L000002",
          "Order Effective from Date": "",
          "Order Effective to Date": "",
          "Application Number": "",
          "Date of Exchange Circular": "",
          "CP2 Number": "",
          "Name of Office": "",
          "Number of Employees": "",
          "Inspection Division": "",
          "Identification Details City": "",
          "Name in Original Script": "",
          "Address Note": "",
          "Date of Birth Note": "",
          "Employees State Insurance Corporation (ESIC) Code Number": "",
          "Recovery Certificate Number": "",
          "Issued Date": "",
          "Certificate Amount": "",
          "Interest Amount": "",
          "Further Interest Amount": "",
          "Amount Paid": "",
          "Total Amount": "",
          "Balance Amount": "",
          "Default Amount": "",
          "Due Amount": "",
          "Certificate Type": "",
          "Period of Claim": "",
          "Claim Type": "",
          "Case Status": "",
          "First Information Report FIR Number": "",
          "State Match Status": "",
          "State Match Details": "",
          "State": "",
          "Rule Applied": "",
          "Priority": "",
          "Passport": "",
          "Position": "",
          "DIN": "",
          "Reason": "",
          "Country": "Myanmar",
          "Authority Name": "Wildlife Crime Control Bureau",
          "ReasonofSuspension": "",
          "DOB": "",
          "DefaulterExpelledSuspensiondate": "",
          "Charges": "",
          "Age": "",
          "DobScore": "0%",
          "Current Status": "",
          "PenaltyAmount": "10,000",
          "fuzzy_score": "72%",
          "Reward": "",
          "ListName": "Wildlife Crime Control Bureau - Convicts",
          "Status": "",
          "Address": "Myanmar",
          "SourceLink": "http://wccb.gov.in/Content/Convicts.aspx",
          "details": "aung koko ming",
          "SourceName": "India Wildlife Crime Control Bureau",
          "MatchedPanNo": "",
          "Nationality": "",
          "Image Url": "",
          "Name": "Aung Koko Ming",
          "DistinguishingMarks": "",
          "Alias": "Ko Lak",
          "DIN_match_details_ds": "",
          "NameofAssociatedCompany": "",
          "DobMatchDetails": "",
          "Gender": "",
          "PAN match status": "",
          "Email Match Status": "Not Available",
          "Email": "",
          "Email Match Details": "",
          "Phone Number": "",
          "Phone Number Match Status": "Not Available",
          "Phone Number Match Details": "",
          "DIN_match_status_ds": "",
          "Branch": "",
          "Outstanding Amount": "",
          "Lender Bank": "",
          "Court Name": "Court of Addl. District & Session Judge",
          "Quarter": "",
          "CourtJudgement": "",
          "CaseName": "",
          "CaseNumber": " 203/2013 ",
          "Section": "Section 50/51 Wild Life (P) Act",
          "Appeal Number": "",
          "Date of Appeal": "",
          "Notice Date": "",
          "Suit": "",
          "SEBI Reference Number": "",
          "Vendor Identification Number": "",
          "Debarred Date From": "",
          "Debarred Date To": "",
          "SEBI Registration Number": "",
          "Complaint Number": "",
          "Registration Number": "",
          "Violation": "",
          "Forward Markets Commission (FMC) Registration Number": "",
          "Institute of Chartered Accountants of India (ICAI) Membership Number": "",
          "Outcome": "",
          "Purpose": "",
          "Name of Respondent": "",
          "Defendant Name": "",
          "Account Name": "",
          "Nature of Offence": "",
          "Imprisonment Term": "",
          "Government Notification Number": "",
          "Date of Offence": "",
          "Absconding Status": "",
          "Absconding Date": "",
          "Action Taken": "",
          "Action": "",
          "Case Summary": "",
          "Police Station (PS)": "",
          "First Information Report FIR Date": "",
          "Date of Filing": "",
          "Next Listing Date": "",
          "Name of All Petitioners": "",
          "Name of All Respondents": "",
          "Date of Press Release": "",
          "Investigation Date": "",
          "Investigation Status": "",
          "Seized Amount": "",
          "Date of Arrested": "",
          "Case Filing Date": "",
          "Scrip Name": "",
          "Filing Number": "",
          "Passport Match Details": "",
          "composite_score": "72%",
          "Case Number": " 203/2013 ",
          "CIN_match_status_ds": "",
          "CIN": "",
          "CIN_match_details_ds": "",
          "Identifier Match Status": "",
          "Identifier Match Score": "",
          "Identifier Match Details": [],
          "Identifier": [],
          "Other Details": [],
          "Goods and Services Tax (GST) Network Number": "",
          "timestamp": "10 May 2024, GMT 02:07:26",
          "match_score": 1,
          "match_status": "Green",
          "Comment": "",
          "Match": "",
          "FinalStatus": "",
          "FinalScore": "",
          "FinalTimeStamp": "",
          "Reviewer": "",
          "ReviewerId": 0,
          "Label": "",
          "RunId": 217134,
          "Attachment": "",
          "fileName": "",
          "mappingId": 3,
          "InputType": "Individual",
          "InputEntityName": "Aung Pang Sone",
          "InputAlias": [],
          "InputIdentifier_4": [],
          "InputDesignation": "",
          "InputAssociations": [],
          "InputAddress": "",
          "InputState": [],
          "InputCountry": [
            "Myanmar"
          ],
          "InputCity": "",
          "InputCin": "",
          "InputDin": "",
          "InputEmailID": [
            "abc@gmail.com"
          ],
          "InputPhoneNumber": [
            "+911234567890"
          ],
          "InputSector": "",
          "InputIndustry": "",
          "InputOrganization": [],
          "InputDateofBirth": "02 Nov 2000",
          "InputPan": "",
          "InputGender": "",
          "InputNationality": "string",
          "InputVesselsType": "",
          "InputCountryOfResidence": [
            "India"
          ],
          "InputPassport": [],
          "InputIdIdentifiers": [],
          "InputDateOfIncorporation": ""
        }
      ]
    }
  ],
  "Case_Outcome": {
    "Name": "Aung Pang Sone",
    "CaseId": "CL1684075",
    "CompositeApplicationStatus": "Need Review",
    "Score": "5/10",
    "Status": "Amber",
    "Uid": "51d354e4-d2a7-4923-970d-8e9cec9d895a",
    "EntityName": "Aung Pang Sone"
  },
  "CaseAssociation": [
    {
      "Name": "Aung Pang Sone",
      "Score": "0/10",
      "Status": "Green",
      "Type": "Individual",
      "Uid": "60387caf-98ca-4734-ada8-36a6321b365b"
    }
  ],
  "Subscribed": {},
  "ListSubscribed": {
    "message": "You have not subscribed to these list(s)",
    "body": {
      "ListName": [],
      "ListUid": []
    }
  },
  "NotExistsList": {
    "message": "list(s) not exists",
    "body": {
      "ListName": [],
      "ListUid": []
    }
  }
}
   
export const pythonResAuth = `
import http.client
conn = http.client.HTTPSConnection("clientdevapiv2.amlpenalties.com")
payload = ''
headers = {
'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NjI2MjQ5MjN9.6Z5H3YG7eEggheMuuJrjSP8BPyp96WjUiqPv_-l-ltI'
}
conn.request("GET", "/api/cms/add_blog_page_get_byname/CRYPTO-AML", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))

`
export const  DiffkindReportDownloadAPI={
    "pdfUrl": "CL95389_Summary.pdf",
    "message": "use this url to download your pdf."
}
