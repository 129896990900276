export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CMS_BASE_URL=process.env.REACT_APP_CMS_URL;

// ------------------|SEND MAIL ENDPOINTS |------------------
export const SEND_MAIL_BASE_URL =`${BASE_URL}api/ContactUs`;
// ------------------|CMS Endpoints|------------------------

export const BLOG_LIST = `${CMS_BASE_URL}cms/add_blog_page_list_public`
export const BLOG_DETAIL=`${CMS_BASE_URL}cms/add_blog_page_get_byname/`
//-------------------------|CRM ENDPOINTS ----------------------
export const CRM_ENTRY_BASE_URL =`${CMS_BASE_URL}cms/insert_crm_request`