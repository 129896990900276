import {LandingInstance} from './axios-file';

import { TICKER,
  SEND_MAIL_BASE_URL,
 BLOG_LIST,
 BLOG_DETAIL,
CRM_ENTRY_BASE_URL
} from './api-endpoints';



// ------------------|SEND MAIL ENDPOINTS |------------------
export function sendMail(mailData) {
  return LandingInstance.post(SEND_MAIL_BASE_URL,mailData);
}
 
// ------------------|CMS Endpoints|------------------------
export function BlogList(page_no,page_limit) {
  return LandingInstance
    .get(BLOG_LIST, { params: {page_no,page_limit} })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}
export function BlogDetail(name) {
  let url = `${BLOG_DETAIL}${name}`
  return LandingInstance
    .get(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}
export function CRM_Entry(first_name, last_name, email, subject, mobile,message) {
  return LandingInstance
    .post(CRM_ENTRY_BASE_URL,null,{
      params:{
        first_name,
        last_name,
        email,
        subject,
        mobile,
        message
      }
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}