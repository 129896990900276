import React, { PureComponent } from 'react';
import $ from 'jquery';
import Apitablecomponent from './apitablecomponent';
import ApiMultiLanguageComponent from './ApiMultiLanguageComponent';
import ScrollspyNav from "react-scrollspy-nav";
import {screeningAPIReqMsg,screeningAPIResMsg,authresponse, pythonResAuth,DiffkindReportDownloadAPI} from "./apidocsconstant"
import cogoToast from 'cogo-toast';
class apidoc extends PureComponent {

   constructor() {
      super();
      window.scrollTo(0, 0);
   }

   componentDidMount() {
      window.scrollTo(0, 0);
      var initTopPosition = $('#possticky').offset().top;
      $(window).scroll(function () {
         console.log(initTopPosition,$(window).scrollTop());
         if ($(window).scrollTop()+110 > initTopPosition)
            $('#possticky').css({ 'position': 'sticky','top':'100px'});
         else
            $('#possticky').css({ 'position': '', 'top': '', 'width': '' });
      });

   }
   copydata = (val) => {
      navigator.clipboard.writeText(val);
      cogoToast.success("Copied to clipboard");
   }
   handleShow = (e) => {
      e.preventDefault();
      const target = window.document.getElementById(
         e.currentTarget.href.split("#")[1]
      );
      if (target) {
         var headerOffset = 89;
         var elementPosition = target.getBoundingClientRect().top;
         var offsetPosition = elementPosition;

         window.scrollBy({
            top: offsetPosition,
         });
      };
   }


   render() {
      return (
         <div className='home-page-conatiner landing-page-main-class' id='apidocs'>
          <div className="landing-page-conatiner container pt-110">
                  <div className='row '>
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
                     <h1 className='site-page__heading mb-3'>API DOCUMENTATION</h1>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
                     <span className="font-color-grey font-size-14 mt-3">
                        Prescreening.io has an API-style output designed for developer documentation. It enables users to perform searches on the database and live Adverse Press Coverage search. This document provides a brief API overview, authentication and Prescreening API , Sample Request and Response and Data Field details.
                     </span>
                  </div>
                  </div>
              
      
         <div className="mt-3">
            {/* start page title section */}
            <div className="">
               {/* end page title section */}

               {/* start post content section */}
               <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 mb-4 mb-md-0 col-wd-2 pos-sticky">
                     <nav className="sidebar " id='possticky'>
                        <ScrollspyNav
                           scrollTargetIds={["download-section", "authentication", "PrescreeningAPI","EncodedPrescreeningAPI", "data-fields","pdf-download-api","different-download-api","Response-codes"]}
                           activeNavClass="activeMenu"
                           scrollDuration="10"
                        >
                           <a data-to-scrollspy-id="download-section" onClick={(e) => this.handleShow(e)} href='#download-section' className='nav-link scrollto activeMenu'>
                              API Overview
                           </a>

                           <a data-to-scrollspy-id="authentication" onClick={(e) => this.handleShow(e)} href='#authentication' className={'nav-link scrollto'}>
                              Authentication
                           </a>

                           <a data-to-scrollspy-id="PrescreeningAPI" onClick={(e) => this.handleShow(e)} href="#PrescreeningAPI" className={'nav-link scrollto'}>
                              Prescreening API
                           </a>
                           <a data-to-scrollspy-id="PrescreeningAPI" onClick={(e) => this.handleShow(e)} href="#EncodedPrescreeningAPI" className={'nav-link scrollto'}>
                              Prescreening Encoded API
                           </a>
                           <a data-to-scrollspy-id="data-fields" onClick={(e) => this.handleShow(e)} href="#data-fields" className={'nav-link scrollto'}>
                              Data Field Details
                           </a>
                           <a data-to-scrollspy-id="data-fields" onClick={(e) => this.handleShow(e)} href="#pdf-download-api" className={'nav-link scrollto'}>
                             PDF Download API
                           </a>
                           <a data-to-scrollspy-id="data-fields" onClick={(e) => this.handleShow(e)} href="#different-download-api" className={'nav-link scrollto'}>
                             PDF Report Type Download API 
                           </a>
                           <a data-to-scrollspy-id="data-fields" onClick={(e) => this.handleShow(e)} href="#Response-codes" className={'nav-link scrollto'}>
                           HTTP Response Code Description
                           </a>
                        </ScrollspyNav>
                     </nav>
                  </div>

                  <div className="col-lg-9 col-md-9 col-sm-12 mb-4 mb-md-0 col-wd-2">

                     <section className="section-pb-50" id="download-section">
                        <div className='row'><div className='col-lg-12'>
                           <div className="vi-info-p" >
                              <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5">API Overview</div>
                              <p>Prescreening API is designed for integration with third party platform to provide various screening functionalities across use cases.</p>
                              <p>The aim is to enable screening function in partners platform where screening can be done for single or multiple entities (via batch upload) for individuals and organizations.</p>
                              <p>Please <a href="mailto:contact@prescreening.io?subject=API Enquiry" style={{ textDecoration: "underline" }}>contact us</a> for API related information.</p>
                              <p>API displayed in the documentation is for representation purpose.</p>
                           </div>
                        </div>
                        </div>

                     </section>

                     <section className="section-pb-50" id="authentication">
                        <div className='row'>
                           <div className='col-12'>
                              <div className="vi-info-p" >

                                 <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5">Authentication</div>
                                 <p >It follows standard OAuth2.0 architecture for authentication and authorization. OAuth is a delegated authorization framework for REST/APIs. It enables apps to obtain limited access (scopes) to a user's datawithout giving away a user's password. It decouples authentication from authorization and supports multiple use cases addressing different device capabilities. </p>
                                 <div className=' my-3 apiborderbox col-lg-12 col-md-12 p-3'>    <p><strong>Base URL</strong>: https://datafacade.prescreening.io/ <br /><br /> 
                                    <strong>End point</strong> : TOKEN <br /><br />
                                    <strong>Method</strong>: POST <br /><br />
                                 </p></div> </div>
                              <div className='row  '>
                                 <div className='col-lg-12 col-md-12'>
                                    <div className='mtr-heading font-size-14'>
                                       Example Request
                                    </div><br />
                                    <pre className='req h10rem'>
                                       <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify({
                                           "username":"uAECAwB4fHw+8qO7xV/RedzhGtpkOeLuZ8prTngld82YQi0CKQwBN4ZIMvVX9PhsVNNL3tAFFgAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDIuHqUohA/kstIfKhQIBEIA7mVNQv0gggj78ky8j7noMwd+LrU+uYMBLF9avguqwvJM+oi9cmNUFXjrruRPPa9MfTlWYukMYBLXcMd3Q7PQqr/+7y61hkhelPrs2a1fNXZYySesNnAqnY7qmgg==",
                                           "password":"uAECAwB4fHw+8qO7xV/RedzhGtpkOeLuZ8prTngld82YQi0CKQwBBeLoLaD5ITN14HGYf9I/mwAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLXPC40KwD7hah1ZCQIBEIA7ba8dFys391G/BAxzSwWDEwff8IUOgUXa9zgzSl/7ZNOmbnTiZ2/z9K6wJ35cJUNrdOsnZbcS9bbRI719MHViueHOjPH+iq5PlzMDz0WOcfrne0m+Q6ilT3ztcQ=="
                                       }, null, 2))} aria-hidden="true"></i>
                                       <br />

                                       <code className='test'>
                                          {JSON.stringify({
                                             "username":"uAECAwB4fHw+8qO7xV/RedzhGtpkOeLuZ8prTngld82YQi0CKQwBN4ZIMvVX9PhsVNNL3tAFFgAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDIuHqUohA/kstIfKhQIBEIA7mVNQv0gggj78ky8j7noMwd+LrU+uYMBLF9avguqwvJM+oi9cmNUFXjrruRPPa9MfTlWYukMYBLXcMd3Q7PQqr/+7y61hkhelPrs2a1fNXZYySesNnAqnY7qmgg==",
                                             "password":"uAECAwB4fHw+8qO7xV/RedzhGtpkOeLuZ8prTngld82YQi0CKQwBBeLoLaD5ITN14HGYf9I/mwAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLXPC40KwD7hah1ZCQIBEIA7ba8dFys391G/BAxzSwWDEwff8IUOgUXa9zgzSl/7ZNOmbnTiZ2/z9K6wJ35cJUNrdOsnZbcS9bbRI719MHViueHOjPH+iq5PlzMDz0WOcfrne0m+Q6ilT3ztcQ=="
                                         }, null, 2)}
                                       </code>

                                    </pre>
                                    <div className="mtr-heading font-size-14">Example Response:</div><br />
                                    <pre className='req h10rem'>
                                       <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify(authresponse, null, 2))} aria-hidden="true"></i>
                                       <code>
                                          {JSON.stringify(authresponse, null, 2)}
                                       </code>
                                    </pre>
                                 </div>
                                 {/* <div className='col-lg-6 col-md-12 ' >
                                    <ApiMultiLanguageComponent python={pythonResAuth} json={JSON.stringify(authresponse, null, 2)} />
                                 </div> */}
                              </div>
                           </div>
                        </div>

                     </section>
                     <section className="section-pb-50" id="PrescreeningAPI">
                        <div className="vi-info-p" >
                           <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5">Prescreening API</div>
                           <p>Prescreening API provided risk score for various like PEP, Sanctions, AML etc within seconds to third party
                              platform via secured, stable, and highly available API. All the above checks are configurable for third
                              party platforms. </p>
                           <div className='my-3 apiborderbox col-lg-12 col-md-12 p-3'>
                              <p><strong>Base URL</strong>: https://datafacade.prescreening.io/ <br /><br />
                                 <strong>End point</strong> : api/Screening  <br /><br />
                                 <strong>Method</strong>: POST <br /><br />
                                 <strong>Authentication</strong>: access token recieved from authentication API
                              </p></div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12 col-md-12'>
                              <div className='mtr-heading font-size-14'>
                                 Example Request
                              </div><br />
                              <pre className='req'>
                                 <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify(screeningAPIReqMsg, null, 2))} aria-hidden="true"></i>
                                 <code>
                                    {JSON.stringify(screeningAPIReqMsg, null, 2)}
                                 </code>
                              </pre>
                              <div className="mtr-heading font-size-14">Example Response:</div><br />
                              <pre className='req'>
                                 <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify(screeningAPIResMsg, null, 2))} aria-hidden="true"></i>
                                 <code>
                                    {JSON.stringify(screeningAPIResMsg, null, 2)}
                                 </code>
                              </pre>
                           </div>
                           {/* <div className='col-lg-6 col-md-12 ' >
                              <ApiMultiLanguageComponent json={JSON.stringify(screeningAPIResMsg, null, 2)} python={pythonResAuth} />
                           </div> */}
                        </div>
                     </section>
                     <section className="section-pb-50" id="EncodedPrescreeningAPI">
                        <div className="vi-info-p" >
                           <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5">Prescreening Encoded API</div>
                           <p>
Encoded prescreening API provided risk score for various like PEP, Sanctions, AML etc within seconds to third party platform via secured, stable, and highly available API with encrypted payload. All the above checks are configurable for third party platforms. Encoding is done using AES 256 bit key and utilizing key from AWS. AWS algorithm and credential can be shared on request. </p>
                           <div className='my-3 apiborderbox col-lg-12 col-md-12 p-3'>
                              <p><strong>Base URL</strong>: https://datafacade.prescreening.io/ <br /><br />
                                 <strong>End point</strong> : api/EncodeScreening <br /><br />
                                 <strong>Method</strong>: POST <br /><br />
                              </p></div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12 col-md-12'>
                              <div className='mtr-heading font-size-14'>
                                 Example Request
                              </div><br />
                              <pre className='req2'>
                                 <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify({
                                    "Encrypted Text":""
                                 }, null, 2))} aria-hidden="true"></i>
                                 <code>
                                   Encrypted Text
                                 </code>
                              </pre>
                              <div className="mtr-heading font-size-14">Example Response:</div><br />
                              <pre className='req2'>
                                 <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify({
                                    "Encrypted Text":""
                                 }, null, 2))} aria-hidden="true"></i>
                                 <code>
                                   Encrypted Text
                                 </code>
                              </pre>
                           </div>
                           {/* <div className='col-lg-6 col-md-12 ' >
                              <ApiMultiLanguageComponent json={JSON.stringify(screeningAPIResMsg, null, 2)} python={pythonResAuth} />
                           </div> */}
                        </div>
                     </section>
                     <section className="section-pb-50" id="data-fields">
                        <Apitablecomponent />
                     </section>
                     <section className="section-pb-50" id="pdf-download-api">
                        <div className="vi-info-p" >
                           <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5">PDF Download API</div>
                           <p>Api to download pdf file from link which is getting generated by hitting Screening api.</p>
                           <div className='my-3 apiborderbox col-lg-12 col-md-12 p-3'>
                              <p><strong>Base URL</strong>: https://datafacade.prescreening.io/ <br /><br />
                                 <strong>End point</strong> : DownloadPdfFile<br /><br />
                                 <strong>Method</strong>: GET <br /><br />
                                 <strong>Authentication</strong>: access token recieved from authentication API
                              </p></div>
                        </div>
                        <div className='mtr-heading font-size-16 pb-18 mt-5'>Parameters </div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Filename</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field contains filename</td>
                        </tr>
                        <tr>
                            <td>projectId</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field contains project Id.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                   
                     </section>
                     <section className="section-pb-50" id="different-download-api">
                        <div className="vi-info-p" >
                           <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5"> PDF Report Type Download API </div>
                           <p>Api to download to different kind of reports after hitting the Screening api.</p>
                           <div className='my-3 apiborderbox col-lg-12 col-md-12 p-3'>
                              <p><strong>Base URL</strong>: https://datafacade.prescreening.io/ <br /><br />
                                 <strong>End point</strong> : api/DownloadDifferentPdfReports<br /><br />
                                 <strong>Method</strong>: GET <br /><br />
                                 <strong>Authentication</strong>: access token recieved from authentication API
                              </p></div>
                        </div>
                        <div className='mtr-heading font-size-16 pb-18 mt-5'>Parameters </div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>caseId</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field contains Case ID</td>
                        </tr>
                        <tr>
                            <td>projectId</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field contains project Id.</td>
                        </tr>
                        <tr>
                            <td>reportType</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: Summary, Hits Only Report, Detailed</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                        <div className='row'>
                           <div className='col-lg-12 col-md-12'>
                              <div className="mtr-heading font-size-14">Example Response:</div><br />
                              <pre className='req'>
                                 <i class="fa fa-clone float-right copy" onClick={() => this.copydata(JSON.stringify(DiffkindReportDownloadAPI, null, 2))} aria-hidden="true"></i>
                                 <code>
                                    {JSON.stringify(DiffkindReportDownloadAPI, null, 2)}
                                 </code>
                              </pre>
                           </div>
                           {/* <div className='col-lg-6 col-md-12 ' >
                              <ApiMultiLanguageComponent json={JSON.stringify(screeningAPIResMsg, null, 2)} python={pythonResAuth} />
                           </div> */}
                        </div>
                     </section>
                     <section className="section-pb-50" id="Response-codes">
                        <div className="vi-info-p" >
                           <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5"> HTTP Response Code Description </div>
                        </div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Code </th>
                            <th scope="col" className='width2'>Response</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>401</td>
                            <td>Error: response status is 401</td>
                            <td>The token expired</td>
                        </tr>
                        <tr>
                            <td>400</td>
                            <td>Bad request</td>
                            <td>Invalid Type. Type can only be either Individual, Organization, Vessels or All.</td>
                        </tr>
                        <tr>
                            <td>408</td>
                            <td>Request Timeout Error</td>
                            <td>The server did not receive a complete request from the client within the server's allotted timeout period.</td>
                        </tr>
                        <tr>
                            <td>409</td>
                            <td>Too Many Requests</td>
                            <td>The user has sent too many requests</td>
                        </tr>
                        <tr>
                            <td>500</td>
                            <td>Internal Server Error</td>
                            <td>The server has encountered a situation that it does not know how to handle</td>
                        </tr>
                        <tr>
                            <td>504</td>
                            <td>Gateway Timeout</td>
                            <td>The server is acting as a gateway and cannot get a response in time for a request.</td>
                        </tr>
                        <tr>
                            <td>200</td>
                            <td>Ok</td>
                            <td>Request recieved</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                     </section>
                  </div>
               </div>
               {/* end blog content section */}

            </div>
         </div>
         </div>
         </div>
      );
   }
}

export default apidoc;
