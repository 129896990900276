import React, { useState, useEffect } from "react";
import { VectorMap } from "react-jvectormap";
import numeral from "numeral";

const JvectorMap = (props) => {
  const SELECTED_MAP_COLOR = ["#70C217"];
  const [data, setData] = useState({
    in: "1",
    sg: "1",
    ca: "1",
    us: "1",
    gb: "1",
    ae: "1",
    ng: "1",
    ke: "1",
    za: "1",
    rw: "1",
    mu: "1",
    et: "1",
    id: "1",
    il: "1",
    my: "1",
  });

  const mapStyle = {
    initial: {
      fill: "#ffffff",
      "fill-opacity": 1,
      stroke: "#7854F7",
      "stroke-width": 0.3,
      "stroke-opacity": 0.8,
    },
    hover: {
      "fill-opacity": 1,
      cursor: "pointer",
    },
    selected: {
      fill: "#70C217",
    },
    selectedHover: {},
  };
  return (
    <div>
      <VectorMap
        map="world_mill"
        zoomButtons={false}
        backgroundColor="transparent" // change it to ocean blue: #0077be transparent
        zoomOnScroll={false}
        regionStyle={mapStyle}
        regionsSelectable={false}
        containerClassName="map"
        // onRegionClick={(a, b) => {
        //   return(
        //       <>
        //       <div className=''>
        //           lol
        //       </div>
        //       </>
        //   )
        // }}
        regionsSelectableOne
        series={{
          regions: [
            {
              values: data, // this is your data
              scale: SELECTED_MAP_COLOR, // your color game's here
              normalizeFunction: "polynomial",
              attribute: "fill",
            },
          ],
        }}
        onRegionTipShow={(e, el, code) => {
          //el.html(`${el.html()} ( ${modifiedMapdata[code] || 0})`);

          if (
            data[code] === 0 ||
            data[code] === undefined ||
            data[code] === null
          )
            el.html(`${el.html()}`);
          //el.html(`${el.html()} (${mapData[code]})`);
          else el.html(`${el.html()}`);
        }}
        zoomMin={0.5}
        focusOn={{
          scale: 0.75,
          x: 0,
          y: 0,
        }}
      />
    </div>
  );
};

export default JvectorMap;
