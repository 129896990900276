import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Tab, Tabs } from "react-bootstrap";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import cogoToast from 'cogo-toast';
const ApiMultiLanguageComponent = (props) => {
    const copydata = (val) => {
        navigator.clipboard.writeText(val);
        cogoToast.success('Copied to clipboard')
    }
    return (
        <div className=' apiborderbox apimultilanguagebox mt25rem '>
            <Tabs
                defaultActiveKey="JSON"
                id="justify-tab-example"
                className=" api-tab-container "
                justify
            >
                <Tab eventKey="JSON" title="JSON">
                    <i class="fa fa-clone float-right copy" onClick={() => copydata(props.json)} aria-hidden="true"></i>
                    <SyntaxHighlighter language="json" style={dark} className='codeAPI'>
                        {props.json}
                    </SyntaxHighlighter>
                </Tab>
                <Tab eventKey="Python" title="Python" className='h-100'>
                    <i class="fa fa-clone float-right copy" onClick={() => copydata(props.python)} aria-hidden="true"></i>
                    <SyntaxHighlighter language="python" style={dark} className='codeAPI h-100'>
                        {props.python}
                    </SyntaxHighlighter>
                </Tab>
                <Tab eventKey="C#" title="C#">
                    <div>pass</div>
                </Tab>

            </Tabs>
        </div>
    )
}

export default ApiMultiLanguageComponent