import React from "react";
import JvectorMap from "./JvectorMap";
import MailChimpForm from "../LandingPage/MailChimp";
import $ from "jquery";
const WatchlistPage = (props) => {
  return (
    <div className="home-page-conatiner landing-page-main-class">
      <section className="section-1 purple-background">
        <div className="container">
          <div className=" pt-50 pb-50">
            <div className="row">
              <div className="col-lg-12 col-sm-12  text-center ">
                <div className="max-width ">
                  {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                  <h1 className="Early-Access-Release font-fff">
                    Country Watchlists
                  </h1>
                  <p className="font-fff section-watchlist-title  m-0">
                    Data Assets of various country’s Authoritative Organisations
                    to screen, identify and act on <br/>potential risks on entities
                    in violations.
                  </p>
                </div>
              </div>
            </div>
            <JvectorMap />
            {/* <ReactSimpleMAp/> */}
            <div className="w-100 d-flex justify-content-center">
              <button
                className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free "
                onClick={() => {
                  $("#zsiq_agtpic").click();
                }}
              >
                Book a Discovery Call
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="section-2 ">
        <div className="container">
          <div className=" pt-50 ">
            <div className="row">
              <div className="purpose">
                <h1 className="Made-For-a-Purpose">Type of Lists Covered</h1>
                <p>
                  The type of list covered in a watchlist can vary based on the
                  context and the objectives of the organization or agency
                  maintaining it.
                </p>
              </div>
            </div>
            <div className="row pt-50 ">
              <div className="col-md-4 mb-5">
                <div className="card lg-card-landing  h-100">
                  <div className="row h-100">
                    <div className="col-8 d-flex align-items-center justify-content-center">
                      <p className="card-pera mt-3">
                        Enforcement & Penalty Actions
                      </p>
                    </div>

                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <img
                        loading="lazy"
                        className="card-img-top landing-img-card-top specialheightwidth"
                        src={require("../../assets/Landing page icons/Group (2).svg")}
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 ">
                <div className="card lg-card-landing  h-100">
                  <div className="row h-100">
                    <div className="col-8 d-flex align-items-center">
                      <p className="card-pera mt-3">Warnings</p>
                    </div>

                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <img
                        loading="lazy"
                        className="card-img-top landing-img-card-top"
                        src={require("../../assets/Landing page icons/Group (3).svg")}
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="card lg-card-landing h-100">
                  <div className="row h-100">
                    <div className="col-8 d-flex align-items-center">
                      <p className="card-pera mt-3">Regulatory Alerts</p>
                    </div>

                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <img
                        loading="lazy"
                        className="card-img-top landing-img-card-top"
                        src={require("../../assets/Landing page icons/Group (4).svg")}
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="card lg-card-landing h-100">
                  <div className="row h-100">
                    <div className="col-8 d-flex align-items-center ">
                      <p className="card-pera mt-3">Wanted</p>
                    </div>

                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <img
                        loading="lazy"
                        className="card-img-top landing-img-card-top"
                        src={require("../../assets/Landing page icons/Group 1251.svg")}
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="card lg-card-landing h-100">
                  <div className="row h-100">
                    <div className="col-8 d-flex align-items-center">
                      <p className="card-pera mt-3">
                        Struck off and strike off companies
                      </p>
                    </div>

                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <img
                        loading="lazy"
                        className="card-img-top landing-img-card-top"
                        src={require("../../assets/Landing page icons/Group (5).svg")}
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="card lg-card-landing h-100">
                  <div className="row h-100">
                    <div className="col-8 d-flex align-items-center">
                      <p className="card-pera mt-3">Orders</p>
                    </div>

                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <img
                        loading="lazy"
                        className="card-img-top landing-img-card-top"
                        src={require("../../assets/Landing page icons/Group (6).svg")}
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-1 purple-background">
        <div className="container container-xl-ps  pb-80">
          <div className="row">
            <div className="col-lg-8 col-sm-12 pt-50  text-sm1-center ">
              <div className="max-width ">
                {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                <h1 className="Early-Access-Release font-fff font-custom">
                  The Country Watchlist Library
                </h1>
                <p className="font-fff section-1-title font-custom-para mt-3 pr-5 line-height">
                  A watchlist is a database or list of individuals or entities
                  that are of interest to authorities or organizations for
                  various reasons, typically related to security, compliance, or
                  law enforcement. The purpose of checking names against a
                  watchlist is to identify and monitor individuals or entities
                  that may pose a potential threat, engage in illegal
                  activities, or need to be tracked for regulatory or legal
                  compliance reasons.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 img-div  d-flex align-tems-center ">
              <img
                src={require("../../assets/Landing page icons/Group (1).svg")}
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="section-2 ">
        <div className="container container-xl-ps  pt-80 pb-80">
          <div className="row w-100">
            <div className="purpose w-100">
              <h1 className="Made-For-a-Purpose">Risks Addressed</h1>
              <p>
                Risks addressed through watchlist screening refers to the
                identification and management of potential risks by
                systematically screening individuals, entities, or items against
                watchlists.
              </p>
            </div>
          </div>
          <div className="row pt-80">
            <div className="col-lg-3 col-md-6   mb-3">
              <div className="card lg-card-landing  h-100 min-height-2 ">
                <div className="row h-100">
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <img
                      loading="lazy"
                      className="card-img-top landing-img-card-top-2 my-2"
                      src={require("../../assets/Landing page icons/Group 1072.svg")}
                      alt="Card image"
                    />
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <p className="card-pera mt-3">Reputational</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mb-3">
              <div className="card lg-card-landing h-100 min-height-2 ">
                <div className="row h-100">
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <img
                      loading="lazy"
                      className="card-img-top landing-img-card-top-2 my-2"
                      src={require("../../assets/Landing page icons/Group 1072 (1).svg")}
                      alt="Card image"
                    />
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <p className="card-pera mt-3">Compliance</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6   mb-3 ">
              <div className="card lg-card-landing h-100 min-height-2 ">
                <div className="row h-100">
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <img
                      loading="lazy"
                      className="card-img-top landing-img-card-top-2 my-2"
                      src={require("../../assets/Landing page icons/Group 1072 (2).svg")}
                      alt="Card image"
                    />
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <p className="card-pera mt-3">Emerging</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6   mb-3">
              <div className="card lg-card-landing h-100 min-height-2">
                <div className="row h-100">
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <img
                      loading="lazy"
                      className="card-img-top landing-img-card-top-2 my-2"
                      src={require("../../assets/Landing page icons/Group 1072 (3).svg")}
                      alt="Card image"
                    />
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <p className="card-pera mt-3">Operational</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-2 text-justify purple-background">
        <div className="container container-xl-ps">
          <div className="row">
            <div className="col-lg-6 col-sm-12 img-div text-center d-flex align-tems-center pt-50">
              <object
                data={require("../../assets/Landing page icons/PSIO Vector 02 animated.svg")}
              ></object>
            </div>
            <div className="col-lg-6 col-sm-12 pt-50 ">
              <div className="max-width px-3">
                {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                <h1 className="fontrandom text-left">
                  Still have question?
                  <br />
                  <span> Connect </span>with us
                </h1>
                <div className="my-3">
                  <div className="d-flex listGroup mb-4">
                    <figure className="mr-4 listImage">
                      <img src={require("../../assets/Dark Blue Arrow.png")} />
                    </figure>
                    <div className=" text-left">
                      <h5 className="listItem">
                        Explore our Technology and Data Asset Solutions
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex listGroup my-4">
                    <figure className="mr-4 listImage">
                      <img src={require("../../assets/Dark Blue Arrow.png")} />
                    </figure>
                    <div className=" text-left">
                      <h5 className="listItem">Get Pricing Information</h5>
                    </div>
                  </div>
                  <div className="d-flex listGroup mt-4">
                    <figure className="mr-4 listImage">
                      <img src={require("../../assets/Dark Blue Arrow.png")} />
                    </figure>
                    <div className=" text-left">
                      <h5 className="listItem">Free Trial or Demo</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                <button
                  className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-2"
                  onClick={() => {
                    $("#zsiq_agtpic").click();
                  }}
                >
                  Book a Discovery Call
                </button>
                <button
                  className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                  onClick={() => {
                    $("#zsiq_agtpic").click();
                  }}
                >
                  Book a Free Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-0">
        <div className="section-grey">
          <div className="container container-xl-ps">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <h2 className="WeeklyNewsletter">
                  Subscribe to our weekly newsletter
                </h2>
              </div>
              <div className="col-md-7 col-sm-12">
                <MailChimpForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WatchlistPage;
