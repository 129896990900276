import React, { PureComponent } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MailChimpForm from "./MailChimp";



class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formData: {},
      sender_name: "",
      focus: false,
      demo:false
    };
    this.handleChange = this.handleChange.bind(this);
    AOS.init({
      once: true
    });
  }


  refreshPage() {
    window.location.reload(false);
  }


  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
   
 
  
  render() {
    return (
      <>
        <div className="home-page-conatiner landing-page-main-class">
          <div className="landing-page-conatiner">
            <section className="section-1 purple-background" >
              <div className="container container-xl-ps pt-50 pb-50" >
                <div className="row">
                  <div className="col-lg-7 col-sm-12 pt-110 text-sm1-center ">
                    <div className="max-width " >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="Early-Access-Release font-fff" data-aos="fade-up" data-aos-duration="1250">
                    Prescreening platform to manage customer & third party risk
                    </h1>
                    <p className="font-fff section-1-title my-3" data-aos="fade-up" data-aos-duration="1250">
                    Designed to highlight AML, Financial Crime, Emerging, and other business risks via a quick scan
                    </p>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free-2 my-3 mr-4"
                      onClick={() => {
                        $("#zsiq_agtpic").click()
                       }}
                    >
                     Book a Discovery Call 
                    </button>
                    <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={() => {
                        let URl= window.location.href.toLowerCase().includes("fortrex")?`${process.env.REACT_APP_KEYCLOAK_FORTREX_SIGNUP_URL}`:`${process.env.REACT_APP_KEYCLOAK_SIGNUP_URL}`
                        window.open(URl, "_blank")
                       }}
                    >
                     Sign Up For Free  
                    </button>
                    </div>
                    </div>
                  <div className="col-lg-5 col-sm-12 img-div  d-flex align-tems-center ">
                    {/* <img className="screenviewimg"
                      src={require("../../assets/Landing page icons/PrescreeningDashboard.png")}
                    />
                      <img className="relativeimg"
                      src={require("../../assets/Landing page icons/BEARD10.png")}
                    />
                    <img className="relativeimg2"
                      src={require("../../assets/Landing page icons/BEARD07.png")}
                    /> */}
                  <object data={require("../../assets/Landing page icons/PSIO Vector Animated.svg")}></object>
                  </div>
                </div>
              </div>
            </section>


            <section className="section-2 " >
              <div className="container lp-main-container pt-80 pb-80"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="purpose">
                    <h1 className="Made-For-a-Purpose">Features</h1>
                    <p>
                    PreScreening.io is SaaS enterprise platform designed to highlight third party AML and other business risks via quick scan to deliver best-in-class Turn around time and case outcomes. 

                    </p>
                  </div>
                </div>
                <div className="row pt-80">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <object
                        data={require("../../assets/Landing page icons/Base feature icon.svg")}
                      ></object>
                      <div className="purpose-heading font-size-14">
                        AI & ML Powered
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      ML driven real time global news checks

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-1.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                       Dynamic Workflow
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Ensures dynamic workflow across the teams 

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-2.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                        Flexibility
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Flexible as per the requirements of the user. User can carry out different checks with different scoring criteria

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-80">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-3.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                        Highly Scalable
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Highly scalable platform which can be easily expanded/ upgraded on demand

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-4.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                       Easy Integration
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Ensures easy API Integrations
.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-5.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                       User Friendly
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Platform with a user friendly interface

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="section-2 " >
              <div className="container lp-main-container"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50 pb-50">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-sm1-center">
                    <div className="feature-main">
      
                     <div className="demo-card purple-background p-5">
                      <div className="row">
                        <div className=" col-lg-8 col-sm-8 mb-md-0 col-wd-2">
                        <h1 className="Early-Access-Release font-fff my-5">
                        Interested? Book a Free Demo!
                    </h1>
                        </div>
                        <div className="col-lg-4 col-sm-4  mb-md-0 col-wd-2">
                        <Link
                      to={{pathname:'/contact-us',
                      state:{
                       Subject:"Prescreening.io - Request Demo"
                      }}}
                      type="button"
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free custom-btn my-5 fs-21px"
                    >
                     Book a Free Demo 
                    </Link>
                        </div>

                      </div>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* <section className="section-2 ">
              <div className="container container-xl-ps pt-25 pb-25" data-aos="fade-up" data-aos-duration="1250"> 
              <MainCarousel/>
               </div>
            </section> */}
            <section className="section-2 section-grey" >
              <div className="container lp-main-container"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-80">
                  <div className="purpose">
                    <h1 className="Made-For-a-Purpose">
                      Benefits
                    </h1>
                    <p className="">
                    It is Built-in with best practices, standards, and features to inherent to the screening process and reduce time, efforts and errors consistently

                    </p>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-5.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Quick Scan
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Ensures Real time and Batch Processing quick scan

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-4.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Due Diligence

                      </div>
                      <div className=" purpose-grey-text font-size-12">
                      Focused inputs, news, analysis and information which may be critical for teams and due diligence operations on client and partners

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-3.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Monitoring

                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Monitoring capabilities for onboarded or watchlist customers

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-2.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Checks

                      </div>
                      <div className=" purpose-grey-text font-size-12">
                      Multiple checks i.e. sanctions, PEP, Adverse Media Coverage, AML Penalties and provision to integrate more

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon-1.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Compliance & Risk Teams

                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Managing teams, resources, training, standards and ensuring alignment with best practices/information on an ongoing basis

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/Base feature icon.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Alerts

                      </div>
                      <div className="purpose-grey-text font-size-12">
                      24*7 alert disambiguation-as-a-service

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-2 text-justify purple-background">
            <div className="container container-xl-ps" data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 img-div text-center d-flex align-tems-center pt-50">
                  <object data={require("../../assets/Landing page icons/PSIO Vector 02 animated.svg")}></object>
                  </div>
                  <div className="col-lg-6 col-sm-12 pt-50 ">
                    <div className="max-width px-3" >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="fontrandom text-left" >
                       Still have question?<br/><span> Connect </span>with us
                    </h1>
                    <div className="my-3">
                      <div className="d-flex listGroup mb-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div className=" text-left">
                          <h5 className="listItem">Explore our Technology and Data Asset Solutions</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup my-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Get Pricing Information</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup mt-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Free Trial or Demo</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-2"
                      onClick={() => {
                        $("#zsiq_agtpic").click()
                       }}
                    >
                     Book a Discovery Call 
                    </button>
                    <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={() => {
                        $("#zsiq_agtpic").click()
                       }}
                    >
                     Book a Free Demo 
                    </button>
                    </div>
                    </div>
                </div>
              </div>
            </section>

{/*  

            <section className="section-2" id="Navcontact">
              <div className="container lp-main-container">
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <h1 className="Made-For-a-Purpose">Contact Us</h1>
                  </div>
                </div>
                <div className="row row-mt pt-50">
                  <Contact demo={this.state.demo} pageName="contact-us" />
                </div>
              </div>
            </section> */}
                <section className="p-0">
              <div className="section-grey" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true" data-aos-offset="300">
                <div className="container container-xl-ps">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h2 className="WeeklyNewsletter">Subscribe to our weekly newsletter</h2>
                 </div>
                 <div className="col-md-7 col-sm-12">
                   <MailChimpForm/>
                 </div>
                 </div>
              </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
