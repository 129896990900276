import React, { PureComponent } from 'react';
import {  sendMail } from '../../../apis/home-api';
import cogoToast from 'cogo-toast';
import CommonHeader from './Header/common-header';
import CommonFooter from './Footer/common-footer';
class Contact extends PureComponent {
  constructor(props) {
    super(props);

    const navUrl = window.location.href.split('/');
    this.state = {
      navUrl:(navUrl && navUrl[3])?navUrl[3]:'',
      isLoading: true,
      list:[],
      sender_name:"",
      subject:this.props.location.state?this.props.location.state.Subject:"",
      sender_email:"",
      recipient_email:"",
      body:"",
      demo:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitContact = this.submitContact.bind(this);
  }
// componentDidUpdate(prevprops){
//   if(prevprops.demo!==this.props.demo){
//     this.setState({demo:this.props.demo , subject:"Prescreening Solution-Request Demo"})
//   }
// }
  // componentDidMount(){
  
  // }

  handleChange(e){
    e.preventDefault();
    this.setState({ [e.target.name] : e.target.value });
  };

  submitContact(e){
     e.preventDefault();

     let formData = {};
     formData['name'] = this.state.sender_name;
     formData['title'] = this.state.subject;
    // formData['recipient_email'] = "noreply_amlpenalties@zigram.tech,ritesh.mohan@zigram.tech";
    // formData['sender_email'] = "admin.manager@zigram.tech";
     if(this.state.body){
        formData['message'] = '<p><b>Message:</b> '+this.state.body+'</p>'+
                           '<p><b>User Name:</b> '+this.state.sender_name+'</p>'+
                           '<p><b>Email:</b> '+this.state.recipient_email+'</p>'+
                           '<p><b>Subject:</b> '+this.state.subject+'</p>';
     }
    //  console.log(formData)

     sendMail(formData)
       .then((res) => {
         if(res.status===400){
          cogoToast.error(res.data.Message)
         }
       if(res.status===200){
        cogoToast.success('Thank you for reaching out to us. We will get back to you shortly')
       }
         
         // alert(res.message);
         this.setState({
             sender_name:"",
             subject:"",
             sender_email:"",
             recipient_email:"",
             body:""
         })
       })
       .catch((err) => {
        cogoToast.error(err.message)
       });
  }

  render() {

    const {list} = this.state;
    const {pageName} = this.props;

    return (
      <div className='home-page-conatiner '>
        {console.log(this.state.subject)}
        <CommonHeader/>
        <div className='container main-container'>
        <div className='row row-mt pt-50'>
      <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
       <div className="contact-form">
          <form onSubmit={this.submitContact}>
            <div className="form-group">
              <label htmlFor="email">Full Name</label>
              <input type="text" className="form-control"
                name="sender_name"
                id="support-form-name"
                placeholder="Full Name*"
                required
                value={this.state.sender_name}
                onChange={this.handleChange}
               />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email Address</label>
              <input type="email" className="form-control"
                name="recipient_email"
                id="support-form-email"
                placeholder="Work Email*"
                required
                value={this.state.recipient_email}
                onChange={this.handleChange}
               />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Subject</label>
              <select className="form-control" id="support-form-subject"
              name="subject"
              onChange={this.handleChange}
              defaultValue={this.state.subject}
              required>
                  <option value="">Select Subject</option>
                 <option value ="Prescreening.io - Request Demo">Request For Demo</option>
                 <option value ="Prescreening Solution-Book a Discovery Call">Book a Discovery Call</option>
                  <option value ="Prescreening.io - API or Enterprise Enquiry">API or Enterprise Enquiry</option>
                  <option value ="Prescreening.io - Collaboration & Partnership">Collaboration & Partnership</option>
                  <option value ="Prescreening.io - Payment and subscription">Payment and subscription</option>
                  <option value="Prescreening.io - Others">Others</option>
                  {/* {list && list.length>0 && list.map(function (item) {
                    return <option key={item.id} value={`AML Penalties - ${item.name}`}>{item.name}</option>
                  })} */}
              </select>

            </div>
            <div className="form-group">
              <label htmlFor="pwd">Message</label>
              <textarea type="text" rows="5" className="form-control font-size-12-input"
                 placeholder="Type Your Message Here"
                 name="body"
                 id="support-form-message"
                 value={this.state.body}
                 onChange={this.handleChange}
              ></textarea>
            </div>
            <button type="submit" className="contact-btn green-bg">Send a Message</button>
          </form>
        </div>
     </div>
     <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
       {/*<img className="contact-img" src={require("../../../assets/images/new-images/contact.png")} />*/}
       <img className="contact-img" src={require("../../../assets/Landing page icons/PrescreeningContact.png")} />
     </div>
     </div>
     </div>
     <CommonFooter/>
      </div>
    )
  }

}


export default Contact;
