import React from 'react'

const Apitablecomponent = () => {
    return (
        <div className="vi-info-p" >
            <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-5">Data Field Details</div>
            <div className="mtr-heading font-size-16 pb-18">Data Field - Target Entity</div>
            <div className='table-responsive'>
                <table className="table table-bordered" >
                    <thead>
                        <tr>
                            <th className='width2' >Datafield Name </th>
                            <th className='width2'>Type</th>
                            <th className='width2'>Required</th>
                            <th className='width2'>Length</th>
                            <th className='width2'>Single</th>
                            <th className='width2'>Multiple</th>
                            <th className='width2'>Values</th>
                            <th className='width2'>Format</th>
                            <th className='width'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ProjectId</td>
                            <td>int32</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td rowSpan={24} className='p-2 commonRow align-middle'>These are the main
                                Subject Detail fields.
                                ProjectAdminID to be
                                supplied by ZIGRAM.
                                ProjectId will be part of
                                authentication
                                response.</td>
                                
                        </tr>
                        <tr>
                            <td>EntityName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Country</td>
                            <td>Array of strings</td>
                            <td>Yes</td>
                            <td>3</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>CIN</td>
                            <td>string</td>
                            <td>No</td>
                            <td>27</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>L12345ABCDE1C678901</td>
                        </tr>
                        <tr>
                            <td>DIN</td>
                            <td>string</td>
                            <td>No</td>
                            <td>15</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>0123456789</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>50</td>
                            <td>Yes</td>
                           
                            <td></td>
                            <td>All, Individual, Organization, Vessels</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>PassportNumber</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td></td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>StartDate (For negative news Timeline)</td>
                            <td>date</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>(dd-mm-yyyy)<br/>02-11-2000</td>
                        </tr>
                        <tr>
                            <td>EndDate (For negative news Timeline)</td>
                            <td>date</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>(dd-mm-yyyy)<br/>02-11-2001</td>
                        </tr>
                        <tr>
                            <td>Industry </td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Organization</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>VesselsType </td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Identifiers</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Sector</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Aliases </td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Designation </td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>City </td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Male, Female, Others</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Dob</td>
                            <td>date</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>(02-11-2000) Full Date<br/>(00-02-2020) if Date is not available<br/>(00-00-2020) if Date and Month both are not available</td>
                        </tr>
                        <tr>
                            <td>panNumber</td>
                            <td>string</td>
                            <td>No</td>
                            <td>10</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td></td>
                            <td>ABCDE4444X</td>
                        </tr>
                        <tr>
                            <td>State </td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>                        
                        <tr>
                            <td>CountryOfResidence </td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>3</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>                        
                        <tr>
                            <td>PhoneNumbers</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>5</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>+9198765432XX, 0674234XX<br/>Length : 7 - 15</td>
                        </tr>                        
                        <tr>
                            <td>Emails</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>5</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>abcd@email.com</td>
                        </tr>
                    </tbody>
                </table>
                <table className='table table-bordered'>
                    <thead>
                            <th className='width2' >Datafield Name </th>
                            <th className='width2'>Type</th>
                            <th className='width2'>Required</th>
                            <th className='width2'>Length</th>
                            <th className='width2'>Single</th>
                            <th className='width2'>Multiple</th>
                            <th className='width2'>Description</th>
                        </thead>
                    <tbody>
                    <tr>
                            <td>reportType</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Can only have these values:  Summary, Hits Only Report, Detailed</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mtr-heading font-size-16 pb-18 mt-5'>Data Fields – Associated Entity </div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Values</th>
                            <th scope="col" className='width2'>Format</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>AssociationsName </td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td rowSpan={21} className='p-2 commonRow align-middle'>These are the
                                Associations Detail
                                fields. (Json Field
                                Name:-
                                Associations) Note
                                Associations can be multiple</td>
                        </tr>
                        <tr>
                            <td>AssociationsCountry</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>3</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsType</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>50</td>
                            <td>Yes</td>
                            <td></td>
                            <td>All, Individual, Organization, Vessels</td>
                            <td></td>
                            
                        </tr>
                        <tr>
                            <td>AssociationsIndustry</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsOrganization</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationVesselsType</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsIndentifiers</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsSector</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsAliases</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsDesignation</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsCity</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsGender</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Male, Female, Others</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsDob</td>
                            <td>date</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>(02-11-2000) Full Date<br/>(00-02-2020) if Date is not available<br/>(00-00-2020) if Date and Month both are not available</td>
                        </tr>
                        <tr>
                            <td>AssociationPanNumber</td>
                            <td>string</td>
                            <td>No</td>
                            <td>10</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td></td>
                            <td>ABCDE4444X</td>
                        </tr>
                        <tr>
                            <td>AssociationsState</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationDin</td>
                            <td>string</td>
                            <td>No</td>
                            <td>15</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>0123456789</td>
                        </tr>
                        <tr>
                            <td>AssociationCin</td>
                            <td>string</td>
                            <td>No</td>
                            <td>27</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>L12345ABCDE1C678901</td>
                        </tr>
                        <tr>
                            <td>AssociationEmails</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>5</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>abcd@email.com</td>
                        </tr>
                        <tr>
                            <td>AssociationPhoneNumbers</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>5</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td>+9198765432XX, 0674234XX<br/>Length : 7 - 15</td>
                        </tr>
                        <tr>
                            <td>AssociationCountryOfResidence</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>3</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationPassportNumber</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mtr-heading font-size-16 pb-18 mt-5'>Data Fields – Entity Input Check Models (Project Settings) </div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>checkName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: PepCheck,SanctionCheck, AmlCheck, Indian Watchlists</td>
                        </tr>
                        <tr>
                            <td>thresholdName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: Low, Medium, High</td>
                        </tr>
                        <tr>
                            <td>thresholdvalue</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td>50</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: 0, 1, 2</td>
                        </tr>
                        <tr>
                            <td>thresholdStartRange</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value greater than or equal to 0 and up to 100</td>
                        </tr>
                        <tr>
                            <td>thresholdEndRange</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value up to 100</td>
                        </tr>
                        <tr>
                            <td>checksWeightage</td>
                            <td>float</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value up to 1.0</td>
                        </tr>
                        <tr>
                            <td>maxScore</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value up to 2</td>
                        </tr>
                        <tr>
                            <td>ListName</td>
                            <td>string</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>This input will guarantee that your search will take this list into account.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mtr-heading font-size-16 pb-18 mt-5'>Data Fields – Score Status Mappings </div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>score</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value ranging between 0 and 10 </td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>projectId</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This value is assigned by Prescreening's team</td>
                        </tr>
                        <tr>
                            <td>actions</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)
                            </td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mtr-heading font-size-16 pb-18 mt-5'>Data Fields – Algorithm Settings</div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>compositeScoreAlgoName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: max, sum, average</td>
                        </tr>
                        <tr>
                            <td>entityScoreAlgoName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: max, sum, weighted_average</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mtr-heading font-size-16 pb-18 mt-5'>Data Fields – Check Result Count</div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PepCheck</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                        <tr>
                            <td>SanctionCheck</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                        <tr>
                            <td>AmlCheck</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                        <tr>
                            <td>Indian Watchlists</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mtr-heading font-size-16 pb-18 mt-5'>Case Outcome</div>
            <div className='table-responsive'>  <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CaseName</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CaseId</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CompositeApplicationStatus</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Uid</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>EntityName</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='mtr-heading font-size-16 pb-18 mt-5'>Case Associations (Multiple)</div>
            <div className='table-responsive'>   <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>UpdatedStatus</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>ListingId</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>RoleId</td>
                        <td>string</td>
                        <td>int32</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Uid</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>isUpdated</td>
                        <td>booolean</td>
                        <td></td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='mtr-heading font-size-16 pb-18 mt-5'>PEPs (Multiple)</div>
            <div className='table-responsive'>  <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>match_status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Details</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>full_name</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_description</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>per_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Alias</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Timestamp</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='mtr-heading font-size-16 pb-18 mt-5'>Sanctions (Multiple)</div>
            <div className='table-responsive'>  <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>match_status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Details</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>full_name</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_name</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>fuzzy_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>sanction_date</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_description</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Timestamp</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Alias</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Program</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='mtr-heading font-size-16 pb-18 mt-5'>AML Penalties (Single)</div>
            <div className='table-responsive'>   <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>match_status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Details</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Timestamp</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_name</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>fuzzy_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>AmlCategory</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>event_link</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Keywords</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Entities</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>
            <div className='mtr-heading font-size-16 pb-18 mt-5'>PDF</div>
            <div className='table-responsive '>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PdfUrl</td>
                            <td>string</td>
                            <td>max</td>
                            <td>Yes</td>
                            <td></td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default Apitablecomponent